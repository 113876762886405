import { render, staticRenderFns } from "./PendingDistributorComponent.vue?vue&type=template&id=18032266&scoped=true&"
import script from "./PendingDistributorComponent.vue?vue&type=script&lang=js&"
export * from "./PendingDistributorComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18032266",
  null
  
)

export default component.exports